<template>
  <div class="order-status p-0">
    <div class="order-status__nav">
      <ul class="mobile-tabs__nav">
        <li>
          <router-link to="/order-status/in-progress">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 0 ? 'mobile-tabs__title--active' : ''"
            >
              <span class="mobile-tabs__title__count">{{
                activeOrders ? activeOrders.length : "0"
              }}</span>
              <span>In Progress</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/order-status/delivered">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 1 ? 'mobile-tabs__title--active' : ''"
            >
              <span>Delivered</span>
            </div></router-link
          >
          <a href="javascript:void(0)" @click="changeTab(1)">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 1 ? 'mobile-tabs__title--active' : ''"
            ></div>
          </a>
        </li>
      </ul>
    </div>
    <div class="mobile-tabs__indicator-container">
      <div
        class="mobile-tabs__indicator"
        :class="
          orderActiveTab === 1
            ? 'mobile-tabs__indicator--right'
            : 'mobile-tabs__indicator--left'
        "
      ></div>
    </div>
    <b-tabs
      class="mobile-tabs"
      nav-class="d-none"
      content-class="border-red"
      v-model="orderActiveTab"
    >
      <b-tab>
        <template v-if="orderActiveTab === 0">
          <div class="order-status__content slide-pop-up-enter-active">
            <div
              class="loader-spinner__container"
              :style="`height:50px;opacity:${waitingActive ? '1' : '0'}`"
            >
              <img
                class="loader-spinner loader-spinner--sm loader-spinner--left20"
                src="@/assets/static-assets/app-icons/spinner.gif"
                alt="Spinner"
              />
            </div>
            <template v-if="!activeOrders || activeOrders.length < 1">
              <div class="text">No In Progress Orders to show</div>
            </template>
            <template v-else>
              <div class="row">
                <template v-for="item in activeOrders">
                  <div class="col-12 col-lg-6 pt-3" :key="item.id">
                    <order-status-single
                      :shadow="true"
                      :order="item"
                      :delievered="false"
                      :rider="item.rider"
                    />
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </b-tab>
      <b-tab>
        <template v-if="orderActiveTab === 1">
          <div
            class="order-status__content slide-pop-down-enter-active px-3 py-2"
          >
            <div
              class="loader-spinner__container"
              :style="`height:50px;opacity:${waitingDelivered ? '1' : '0'}`"
            >
              <img
                class="loader-spinner loader-spinner--sm loader-spinner--left20"
                src="@/assets/static-assets/app-icons/spinner.gif"
                alt="Spinner"
              />
            </div>
            <template
              v-if="!orderStatusDelivered || orderStatusDelivered.length < 1"
            >
              <div class="text">No Delivered Orders to show</div>
            </template>
            <template v-else>
              <div class="row">
                <template v-for="item in orderStatusDelivered">
                  <div
                    class="col-sm-12 col-md-5 col-xl-4 order-status-delivered px-0"
                    :key="item.id"
                  >
                    <order-status-single
                      :order="item"
                      :shadow="true"
                      :delivered="true"
                      :rider="item.rider"
                    />
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OrderStatusSingle from "@/view/sub-pages/order-status/OrderStatusSingle";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}order-status`,
  components: {
    OrderStatusSingle
  },
  data() {
    return {
      orderActiveTab: 0,
      progressWidth: 0
    };
  },
  methods: {
    getImgUrl(image) {
      const images = require.context(
        "@/assets/static-assets/app-icons/",
        false,
        /\.png$/
      );
      return images(`./${image}.png`);
    }
  },
  mounted() {
    const { id } = this.$route.params;
    if (id === "in-progress") {
      this.orderActiveTab = 0;
    } else if (id === "delivered") {
      this.orderActiveTab = 1;
    } else {
      this.orderActiveTab = 0;
    }
  },
  computed: {
    ...mapState({
      waitingDelivered: state => state.orders.fetchingDelivered,
      waitingActive: state => state.orders.fetchingActive,
      regionCheckUSorOther: state => state.regions.region,
      activeOrders: state => {
        const orders = state.orders.orderStatusActive;
        const squads = state.orders.squadStatusActive;
        // const {
        //   orderStatusActive: orders = [],
        //   squadStatusActive: squads = []
        // } = orders;
        // // const isUs = regions.region;
        let tempOrders = [];
        if (!state.regions.region) {
          tempOrders = orders.map(order => ({
            // eslint-disable-next-line no-underscore-dangle
            _id: order._id,
            stores: order.stores,
            customerName: order.receiver.name,
            avgRating: 2,
            status: order.types.status,
            statusText: "On his way",
            time: order.time.timeToNext,
            id: order.orderId,
            img: "/static-assets/defaults/user.jpg",
            rider: order.rider,
            placedBy: order.placedBy
          }));
        }
        //  tempOrders = orders.map(order => ({
        // //   // eslint-disable-next-line no-underscore-dangle
        //   _id: order._id,
        //   stores: order.stores,
        //   customerName: order.customer.name,
        //   avgRating: 2,
        //   status: order.status,
        //   statusText: "On his way",
        //   time: order.expectedDeliveryTime,
        //   id: order.orderId,
        //   img: "/static-assets/defaults/user.jpg",
        //   rider: order.riderId,
        //   placedBy: "customers"
        // }));
        squads.forEach(squad => {
          const { deliveryRoute } = squad;
          deliveryRoute.forEach(drr => {
            console.log("file: OrderStatus.vue | line 208 | drr", drr);
            const item = {
              _id: null,
              stores: null,
              customerName: drr.customers.map(x => x.customer.name).join(" & "),
              avgRating: 2,
              status: drr.status,
              statusText: "On his way",
              time: 100,
              id: squad.inviteId,
              img: "/static-assets/defaults/user.jpg",
              rider: drr.rider,
              placedBy: "customers"
            };
            tempOrders.push(item);
          });
        });
        // /// Please check expected time or time u can Say
        return tempOrders;
      },
      orderStatusDelivered: ({ regions, orders: storeOrders = {} }) => {
        // const regionChecker = state.regions.region;
        const { orderStatusDelivered: orders = [] } = storeOrders;
        if (!regions.region) {
          return orders.map(order => ({
            // eslint-disable-next-line no-underscore-dangle
            _id: order._id,
            stores: order.stores,
            customerName: order.receiver.name,
            avgRating: 2,
            status: order.types.status,
            statusText: "On his way",
            time: order.time.timeToNext,
            id: order.orderId,
            img: "/static-assets/defaults/user.jpg",
            rider: order.rider,
            placedBy: order.placedBy
          }));
        }
        return orders.map(order => ({
          // eslint-disable-next-line no-underscore-dangle
          _id: order._id,
          stores: order.stores,
          customerName: order.customer.name,
          avgRating: 2,
          status: order.status,
          statusText: "On his way",
          time: order.totalOrderTime.text,
          id: order.orderId,
          img: "/static-assets/defaults/user.jpg",
          rider: order.riderId,
          placedBy: "customers"
        }));
      }
    })
  },
  watch: {
    $route(to) {
      const { id } = to.params;
      if (id === "in-progress") {
        this.orderActiveTab = 0;
      } else if (id === "delivered") {
        this.orderActiveTab = 1;
      } else {
        this.orderActiveTab = 0;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
